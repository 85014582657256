import type { WritableComputedRef } from "vue";
import { useDebounceFn } from "@vueuse/core";

type ToWritableComputed<T extends object> = {
  [K in keyof T]: WritableComputedRef<T[K]>;
};

export function toWritableComputedRefs<T extends object>(
  object: WritableComputedRef<T>,
) {
  // We clone the original object, so we can perform batch updates all at once.
  // If we change two keys at the same time, and the computation getter is asynchronous,
  // then some values may be reset when running all the setters. Therefore we batch all updates.
  let newObject = { ...object.value };

  const refs = Object.fromEntries(
    Object.keys(object.value).map((key) => [
      key,
      computed({
        get() {
          newObject = { ...object.value };
          return object.value[key as keyof T];
        },
        set(value) {
          newObject[key as keyof T] = value;
          debouncedUpdateObject();
        },
      }),
    ]),
  ) as ToWritableComputed<T>;

  function updateObject() {
    object.value = newObject;
    newObject = { ...object.value };
  }

  const debouncedUpdateObject = useDebounceFn(updateObject, 10);

  return refs;
}
