import { useRoute } from "#imports";
import type { LocationQueryValue } from "#vue-router";
import type { UnwrapNestedRefs } from "vue";

export const useRouteParam = (param: string) => {
  const route = useRoute();

  return computed(() => route.params[param] as string);
};

type PossibleQueryType = "string" | "number" | "boolean";
type AppendDotArray<T extends string> = `${T}` | `${T}.array`;
type AppendNullable<T extends string> = `${T}` | `${T}.nullable`;

type InferTypeFromString<T extends string> = T extends "string"
  ? string
  : T extends "number"
    ? number
    : T extends "boolean"
      ? boolean
      : never;

export type InferTypeFromComplexString<T extends string> =
  T extends `${infer FirstType}.${infer Rest}`
    ? Rest extends "array"
      ? InferTypeFromString<FirstType>[]
      : Rest extends "nullable"
        ? InferTypeFromString<FirstType> | null
        : Rest extends "array.nullable"
          ? InferTypeFromString<FirstType>[] | null
          : never
    : InferTypeFromString<T>;

export type QueryTemplate = {
  [q: string]: {
    type: AppendNullable<AppendDotArray<PossibleQueryType>>;
    default: any;
    cast?: (val: any) => any;
  };
};

export type ParsedQueryFromTemplate<StartTemplate extends QueryTemplate> = {
  [Q in keyof StartTemplate]: StartTemplate[Q]["cast"] extends (
    val: any,
  ) => infer U
    ? U
    :
        | InferTypeFromComplexString<StartTemplate[Q]["type"]>
        | StartTemplate[Q]["default"];
};

export function useRouteQueryTemplate<T extends QueryTemplate>(template: T) {
  const route = useRoute();

  const typeToConstructorMap = {
    string: String,
    // this is so we can handle these separately
    id: String,
    number: Number,
    boolean: Boolean,
  };

  const computedTemplate = computed({
    get() {
      const final: Record<string, any> = {};

      Object.entries(template).forEach(
        ([
          qName,
          {
            type: qComplexType,
            default: qDefault,
            cast: qCast = (val: any) => val,
          },
        ]) => {
          const [qType, firstFlag, secondFlag] = qComplexType.split(".") as [
            PossibleQueryType,
            "array" | "nullable" | undefined,
            "nullable" | undefined,
          ];

          const queryCanBeNull =
            firstFlag === "nullable" || secondFlag === "nullable";
          const queryIsArray = firstFlag === "array";

          const queryValue = getDecodedQuery(route.query[qName]);

          // We found the query in the URL
          if (queryValue) {
            // The flag is for it to be an array
            if (queryIsArray) {
              // We declare that it's an array but let's just check to be sure
              if (Array.isArray(queryValue)) {
                final[qName] = qCast(
                  queryValue
                    .filter(onlyTruthys)
                    .map((x) => typeToConstructorMap[qType](x)),
                );
                // Otherwise just put it in an array and transform it appropriately
              } else {
                final[qName] = qCast([typeToConstructorMap[qType](queryValue)]);
              }
            } else {
              // If we don't have a flag for an array just make sure it isn't an array in our query
              if (!Array.isArray(queryValue)) {
                final[qName] = qCast(typeToConstructorMap[qType](queryValue));
                // Otherwise just compromise by using the first element...
              } else {
                final[qName] = qCast(
                  typeToConstructorMap[qType](queryValue[0]),
                );
              }
            }
            // We didn't find the query in the URL, use the default value or null if the apporpriate flag is there
          } else {
            if (typeof qDefault !== "undefined") {
              final[qName] = qCast(qDefault);
            } else if (queryCanBeNull) {
              final[qName] = qCast(null);
            } else {
              final[qName] = qCast(undefined);
            }
          }
        },
      );

      return final as ParsedQueryFromTemplate<T>;
    },
    set(value) {
      const filteredValue = Object.fromEntries(
        Object.entries({ ...route.query, ...value }).filter(([key, value]) => {
          // This is an unrelated query
          if (!Object.keys(template).includes(key)) return true;

          if (typeof value === "string" && !value) return false;
          if (value === template[key].default) return false;
          return true;
        }),
      );

      navigateTo({
        query: filteredValue,
      });
    },
  });

  return toWritableComputedRefs(computedTemplate);
}

function getDecodedQueryValue(q: LocationQueryValue) {
  if (q === null) {
    return null;
  }

  return decodeURIComponent(q);
}

function getDecodedQuery(query?: LocationQueryValue | LocationQueryValue[]) {
  if (Array.isArray(query)) {
    return query.map((q) => getDecodedQueryValue(q));
  } else if (typeof query === "undefined") {
    return undefined;
  } else {
    return getDecodedQueryValue(query);
  }
}
